import React, { useEffect, useRef, useState } from 'react'
import aiAvatar from '../assets/ask-angie.png'
import { Button, IconButton, Avatar } from '@mui/material'
import { AiOutlineClose } from 'react-icons/ai'
import { BiCheckDouble, BiSolidChat } from 'react-icons/bi'
import { MdOutlineSend } from 'react-icons/md'
import { format } from 'date-fns'
import axios from 'axios'
import ReactMarkdownRenderer from '../Components/MarkDown/MarkDown'
import { RxCrossCircled } from 'react-icons/rx'
import ReCAPTCHA from 'react-google-recaptcha'
import { urlRegex } from '../helpers/constant'
import { FaUser } from 'react-icons/fa'
import { TiInfoOutline } from 'react-icons/ti'
import { v4 as uuidv4 } from 'uuid'

const SearchBarWidget = (props) => {
  const { backendURL, logo, avatar, recaptchaToken, recaptchaTokenApi, botResponseApi } = props
  const containerRef = useRef(null)
  const captchaRef = useRef(null)
  const [messages, setMessages] = useState([])
  const [openChat, setOpenChat] = useState(false)
  const [isRotated, setIsRotated] = useState(false)
  const [loading, setLoading] = useState(false)
  let [question, setQuestion] = useState('')
  const ROUTE = backendURL?.length ? urlRegex?.test(backendURL) ? backendURL : false : false

  useEffect(() => {
    scrollToBottom()
  }, [messages, openChat])

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }

  const handleSpeedDialClick = () => {
    setOpenChat(!openChat)
    setIsRotated(!isRotated)
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault()
      handleNewUserMessage(e)
    }
  }

  const verifyRecaptch = async (v) => {
    if (ROUTE === false || !ROUTE?.length) {
      return
    }
    const currentTime = format(new Date(), 'h:mm a')
    let response = await axios.post(recaptchaTokenApi, { token: v, transaction_id: uuidv4() })
    if (response?.data?.status === 'success') {
      setMessages([...messages, { type: 'a', message: 'Hello! I’m your personal AI Assistant.', time: currentTime }])
    }
  }

  const handleNewUserMessage = () => {
    question = question?.trim()
    if (!question) {
      return
    }
    setQuestion('')
    setLoading(true)

    const currentTime = format(new Date(), 'h:mm a')
    let questionObj = { type: 'q', message: question, time: currentTime }
    const newData = [...messages, questionObj]
    setMessages(newData)

    const payload = {
      search_query: question,
      session_id: messages?.[1]?.session_id?.length ? messages?.[1]?.session_id : messages?.[2]?.session_id || messages?.[3]?.session_id,
      transaction_id: uuidv4()
    }

    axios.post(botResponseApi, payload)
      .then((data) => {
        if (data?.data?.status === 'success') {
          const currentTime = format(new Date(), 'h:mm a')
          let answerObj = { type: 'a', message: data?.data?.data, time: currentTime, session_id: data?.data?.session_id }
          setMessages((prevMessages) => [
            ...prevMessages,
            answerObj
          ]);
        }
      })
      .catch((error) => {
        console.log('err', error)
      })
      .finally(() => {
        setLoading(false)
        setQuestion('')
      })
  }

  return (
    <>
      <div className="custom_chat_open_btn">
        <div className="custom_chat_open_btn">
          <div
            className={`speed-dial-button ${openChat ? 'open' : ''}`}
            onClick={handleSpeedDialClick}
          >
            <div className={`icon-container ${openChat ? 'rotated' : ''}`}>
              {openChat ? (
                <AiOutlineClose style={{ color: 'white', height: '35px', width: '35px' }} />
              ) : (
                <BiSolidChat style={{ color: 'white', height: '35px', width: '35px' }} />
              )}
            </div>
          </div>
          {openChat ?
            <div className={`custom_chat_main_div ${openChat ? 'open' : ''}`}>
              <header className="h-12 flex flex-grow-0 items-center justify-between p-3 bg-white rounded-tl-lg rounded-tr-lg border-header">
                <div className="flex items-center">
                  <img
                    src={logo}
                    alt="Logo"
                    className="h-6 mr-2"
                  />
                </div>
                <IconButton
                  onClick={() => setOpenChat(false)}
                  sx={{
                    borderRadius: '50%',
                    padding: '2px',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    }
                  }}
                >
                  <RxCrossCircled className="text-gray-500" />
                </IconButton>
              </header>
              {ROUTE ?
                <>
                  {
                    messages?.length ?
                      <div className="custom_chat_body_div" ref={containerRef}>
                        <>
                          {messages?.map((message, index) => {
                            if (message?.type == 'q') {
                              return (
                                <div key={index} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: '8px' }}>
                                  <div className="text-right custom_chat_user_prompt">
                                    <p className="text-white p-0  custom_chat_user_prompt_para" style={{ padding: '5px', margin: '0px' }}>
                                      <ReactMarkdownRenderer markdownText={message?.message} />
                                    </p>
                                    <div className="flex items-center p-0 ml-1  justify-end space-x-1">
                                      <span className="text-xs text-gray-200">{message.time}</span>
                                      <BiCheckDouble size={20} color='#ffffff' />
                                    </div>
                                  </div>
                                  <div className={`avatar-container`}>
                                    <div className='user-avatar'>
                                      <FaUser />
                                    </div>
                                  </div>
                                </div>
                              )
                            } else {
                              return (
                                <div key={index} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '8px' }}>
                                  <div className={`avatar-container`}>
                                    <Avatar
                                      src={avatar}
                                      alt="diSearch"
                                      className='custom_ai_avatar'
                                      style={{ width: 34, height: 34 }}
                                    />
                                  </div>
                                  <div className="custom_chat_sender_prompt message-bubble">
                                    <p className="p-0 m-0 text-black">
                                      <ReactMarkdownRenderer markdownText={message?.message} />
                                    </p>
                                    <div className="flex items-center justify-end mt-1 space-x-1">
                                      <span className="text-xs text-gray-500">{message.time}</span>
                                      <BiCheckDouble size={20} color='#1C7DFF' />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          })}
                          {loading && (
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                              <div className={`avatar-container`}>
                                <Avatar
                                  src={aiAvatar}
                                  alt="diSearch"
                                  size="default"
                                  className='custom_ai_avatar'
                                  style={{ width: 34, height: 34 }}
                                />
                              </div>
                              <div className="ml-2  typing-loader">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                      :
                      <div className='recatcha-main' style={{ height: '50vh' }}>
                        <ReCAPTCHA
                          sitekey={recaptchaToken}
                          onChange={(v) => verifyRecaptch(v)}
                          ref={captchaRef}
                        />
                      </div>
                  }
                  <div className="chat-input-container">
                    <textarea
                      disabled={!messages?.length || loading}
                      onKeyDown={handleKeyUp}
                      value={question}
                      onChange={(e) => setQuestion(e?.target?.value)}
                      className="chat_input"
                      placeholder="Type your message here..."
                    />
                    <Button
                      className="send-button"
                      onClick={handleNewUserMessage}
                      disabled={loading || !question.trim()}
                    >Send<MdOutlineSend />
                    </Button>
                  </div>
                </>
                :
                <div className='info-message-main'>
                  <TiInfoOutline fontSize={200} color='red' />
                  <p>{!backendURL?.length ?
                    "The backend URL is missing in the widget configuration." : !urlRegex?.test(backendURL) ?
                      "Please add the correct URL in the widget configuration." : ""}</p>
                </div>
              }
            </div>
            : null}
        </div>
      </div>
    </>
  );
};

export default SearchBarWidget;
