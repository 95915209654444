
let ROUTE = 'https://assistant.disearch.ai'
let LOGO = 'https://storage.googleapis.com/di_search_test_bucket/diSearchIcons/Header/diSearch%20Logo.svg'
const captchClientKey = '6LdhmzUpAAAAAJCDRxJ3e8w06uMY23iHaWG0W2y0'
const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/

export {
    ROUTE,
    LOGO,
    captchClientKey,
    urlRegex
}