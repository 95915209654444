import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import SearchWidget from './SearchWidget'

const rootElement = document.getElementById('root')

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}

// Export the widget as a global variable for CDN usage
window.SearchWidget = SearchWidget

// Render the widget if the specific element is found
const widgetElement = document.getElementById('widget')
const apiToken = document.getElementById('widget')?.dataset?.apiToken
const logo = document.getElementById('widget')?.dataset?.logo
const backendURL = document.getElementById('widget')?.dataset?.route
const avatar = document.getElementById('widget')?.dataset?.avatar
const recaptchaToken = document.getElementById('widget')?.dataset?.recaptchaToken
const botResponseApi = document.getElementById('widget')?.dataset?.botResponseApi
const recaptchaTokenApi = document.getElementById('widget')?.dataset?.recaptchaTokenApi

if (widgetElement) {
  const widgetRoot = ReactDOM.createRoot(widgetElement)
  widgetRoot.render(
    <React.StrictMode>
      <SearchWidget recaptchaTokenApi={recaptchaTokenApi} botResponseApi={botResponseApi} recaptchaToken={recaptchaToken} avatar={avatar} apiToken={apiToken} backendURL={backendURL} logo={logo} />
    </React.StrictMode>
  )
}
reportWebVitals()
