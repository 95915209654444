import React, { useEffect } from 'react'
import 'highlight.js/styles/atom-one-dark.css'
import "katex/dist/katex.min.css"
import ReactMarkdown from 'react-markdown'
import RemarkBreaks from 'remark-breaks'
import RemarkGfm from 'remark-gfm'
import RehypeHighlight from 'rehype-highlight'
import './ReactMarkdownRenderer.css'

const ReactMarkdownRenderer = ({ markdownText, isGeminiImage = false }) => {
    useEffect(() => {
        document.querySelectorAll('pre code').forEach((block) => {
            const button = document.createElement('button')
            button.innerText = 'Copy'
            button.className = 'copy-button'
            block.parentElement.appendChild(button)

            button.addEventListener('click', () => {
                const code = block.innerText
                navigator.clipboard.writeText(code).then(() => {
                    button.innerText = 'Copied!'
                    setTimeout(() => {
                        button.innerText = 'Copy'
                    }, 2000)
                })
            })
        })
    }, [markdownText])

    return (
        <div className='markdown-gem'>
            <ReactMarkdown
                className='markfont'
                remarkPlugins={[RemarkGfm, RemarkBreaks]}
                rehypePlugins={[RehypeHighlight]}
            >
                {markdownText}
            </ReactMarkdown>
        </div>
    )
}

export default ReactMarkdownRenderer
